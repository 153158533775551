/**
 * @function checkIpOrLocalhost
 * @param {string} origin
 * @returns {boolean}
 * @description Checking if is being tested.
 */

function checkIpOrLocalhost(origin) {
  if (!origin) return false;
  const match = new RegExp(
    /^(?:http:\/\/|https:\/\/)?([\w.-]+)(?::\d+)?\/?$/
  ).exec(origin);

  const ipAddress = match ? match[1] : origin;

  const ipv4RegExp = /^(?:\d{1,3}\.){3}\d{1,3}$/;
  const ipv4Pattern = new RegExp(ipv4RegExp);

  if (ipv4Pattern.test(ipAddress)) {
    return true;
  } else if (ipAddress.includes("localhost")) {
    return true;
  } else {
    return false;
  }
}

export default checkIpOrLocalhost;
