import getEnvVariable from "../configs/env";
class API {
  static async get(config) {
    try {
      const { url, token } = this.parseConfig(config);

      const API_URL = getEnvVariable("API_URL");
      const API_ADMIN_URL = getEnvVariable("API_ADMIN_URL");

      const apiUrl = !url.startsWith("/get-unsec/private-label")
        ? API_URL
        : API_ADMIN_URL;

      const response = await fetch(`${apiUrl}${url}`, {
        method: "GET",
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      });
      if (response.status > 300) {
        throw new Error((await response.json()).message);
      }
      return {
        status: response.status,
        data: await response.json(),
      };
    } catch (error) {
      console.error(error);
      this.checkTokenExpired();
      return {
        status: 400,
        error: String(error),
      };
    }
  }

  static async post(config) {
    try {
      const { url, token, data, method } = this.parseConfig(config);
      const response = await fetch(`${getEnvVariable("API_URL")}${url}`, {
        method: method ?? "POST",
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
        body: JSON.stringify(data),
      });
      try {
        if (response.status > 300) {
          const error = await response.json();
          return {
            status: response.status,
            errorId: error.error,
            error: error.message,
          };
        }
        return {
          status: response.status,
          data: await response.json(),
        };
      } catch (error) {
        if (response.status < 300 && response.status >= 200) {
          return {
            status: response.status,
            data: undefined,
          };
        } else {
          return {
            status: response.status,
            error: String(error),
          };
        }
      }
    } catch (error) {
      console.error(error);
      this.checkTokenExpired();
      return {
        status: 400,
        error: String(error),
      };
    }
  }

  static parseConfig(config) {
    if (typeof config === "string") {
      return {
        url: config,
        token: localStorage.getItem("NFTdoorUserLOToken"),
      };
    } else {
      return {
        url: config.url,
        token: config.token ?? localStorage.getItem("NFTdoorUserLOToken"),
        method: config.method,
        data: config.data,
      };
    }
  }

  static checkTokenExpired() {
    const expToken = localStorage.getItem("NFTdoorUserLOTokenExp");
    if (expToken) {
      const dateNow = new Date().getTime();
      const authDate = Number(expToken ?? 0);

      if (dateNow > authDate) {
        localStorage.removeItem("NFTdoorUserLOTokenExp");
        window.location.reload();
      }
    }
  }
}

export default API;
