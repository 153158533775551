//@ts-nocheck
import { useCallback } from "react";

/**
 * @description connect user informatio into Full History service
 */
const useFHConnectUser = () => {
  return useCallback((id, name, email) => {
    FS.identify(id, {
      displayName: name,
      email: email,
      reviewsWritten_int: 14,
    });
  }, []);
};

export default useFHConnectUser;
