import "prismjs";
import "./configs/i18n";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import { store } from "./redux/store";
import { Suspense, lazy } from "react";
import { Provider } from "react-redux";

import "./@core/components/ripple-button";
import "prismjs/components/prism-jsx.min";
import "prismjs/themes/prism-tomorrow.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "./@core/assets/fonts/feather/iconfont.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ThemeContext } from "./utility/context/ThemeColors";
import Spinner from "./@core/components/spinner/Fallback-spinner";
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";
import { UserCustomerContextProvider } from "./utility/context/useUserContext";
import { PrivateLabelContextProvider } from "./utility/context/privateLabelContext";
const LazyApp = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <PrivateLabelContextProvider>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <UserCustomerContextProvider>
            <ThemeContext>
              <LazyApp />
            </ThemeContext>
          </UserCustomerContextProvider>
        </Suspense>
      </Provider>
    </BrowserRouter>
  </PrivateLabelContextProvider>
);

serviceWorker.unregister();
