import API from "@src/utility/API";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import UILoader from "../../@core/components/ui-loader";
import checkIpOrLocalhost from "../checkIpOrLocalhost";

export const PrivateLabelContext = createContext({
  privateLabel: undefined,
});

export const PrivateLabelContextProvider = ({ children }) => {
  const [privateLabel, setPrivateLabel] = useState(undefined);
  const [isLoading, setLoading] = useState(true);

  const cleanLOString = (str) => {
    return str
      .replace("lo.", "")
      .replace("remn.", "")
      .replace("homebridge.", "");
  };

  const fetchData = async (hostname) => {
    try {
      const response = await API.get({
        url: `/get-unsec/private-label/${hostname}`,
      });

      if ("error" in response) {
        alert(response.error);
      } else {
        setPrivateLabel(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // used for test privateLabel
    const rawHostname = checkIpOrLocalhost(window.location.hostname)
      ? process.env.REACT_APP_PRIVATE_LABEL_DOMAIN
      : window.location.hostname;
    const hostname = cleanLOString(rawHostname);
    fetchData(hostname);
  }, []);

  const handleSendeskNFTYDoor = (
    privateLabel,
    includeLoanOfficerPortalWidget,
    currentHostname
  ) => {
    if (includeLoanOfficerPortalWidget) {
      if (!document.getElementById("ze-snippet")) {
        if (currentHostname.includes("lo.")) {
          const loanOfficerPortalWidgetUrl =
            privateLabel?.zendesk?.loanOfficerPortalWidgetUrl;
          const scriptElement = document.createElement("script");
          scriptElement.id = "ze-snippet";
          scriptElement.src = loanOfficerPortalWidgetUrl;
          document.head.appendChild(scriptElement);
        } else if (currentHostname.includes("homebridge.")) {
          const loanOfficerHomebridgePortalWidgetUrl =
            privateLabel?.zendesk?.loanOfficerHomebridgePortalWidgetUrl;
          const scriptElement = document.createElement("script");
          scriptElement.id = "ze-snippet";
          scriptElement.src = loanOfficerHomebridgePortalWidgetUrl;
          document.head.appendChild(scriptElement);
        } else if (currentHostname.includes("remn.")) {
          const loanOfficerRemnPortalWidgetUrl =
            privateLabel?.zendesk?.loanOfficerRemnPortalWidgetUrl;
          const scriptElement = document.createElement("script");
          scriptElement.id = "ze-snippet";
          scriptElement.src = loanOfficerRemnPortalWidgetUrl;
          document.head.appendChild(scriptElement);
        }
      }
    }
  };

  const handleSendeskPrivateLabel = (
    includeLoanOfficerPortalWidget,
    privateLabel
  ) => {
    if (includeLoanOfficerPortalWidget) {
      if (!document.getElementById("ze-snippet")) {
        const loanOfficerPortalWidgetUrl =
          privateLabel?.zendesk?.loanOfficerPortalWidgetUrl;
        const scriptElement = document.createElement("script");
        scriptElement.id = "ze-snippet";
        scriptElement.src = loanOfficerPortalWidgetUrl;
        document.head.appendChild(scriptElement);
      }
    }
  };
  useEffect(() => {
    const isNftyDoor = privateLabel?.isNFTYDoor;
    const includeLoanOfficerPortalWidget =
      privateLabel?.zendesk?.includeLoanOfficerPortalWidget;
    const currentHostname = window.location.hostname;
    const isLocalhost = checkIpOrLocalhost(currentHostname);

    if (!isLocalhost) {
      if (isNftyDoor) {
        handleSendeskNFTYDoor(
          privateLabel,
          includeLoanOfficerPortalWidget,
          currentHostname
        );
      } else {
        handleSendeskPrivateLabel(includeLoanOfficerPortalWidget, privateLabel);
      }
    }
  }, [privateLabel]);

  const values = useMemo(() => ({ privateLabel }), [privateLabel]);
  if (isLoading) return <UILoader />;
  if (privateLabel?.favicon) {
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = privateLabel.favicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  if (privateLabel?.lenderName) {
    document.title = `${privateLabel.lenderName} | Loan Officer Dashboard`;
  }

  return (
    <PrivateLabelContext.Provider value={values}>
      {children}
    </PrivateLabelContext.Provider>
  );
};

export function usePrivateLabel() {
  const context = useContext(PrivateLabelContext);
  if (!context) {
    throw new Error("PrivateLabelContextProvider not found");
  }
  return context;
}
