export enum BrokerStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
  PENDING = "pending",
  WITHDRAWN = "withdrawn",
  APPROVED = "approved",
  TERMINATED = "terminated",
  REJECTED = "rejected",
  ARCHIVED = "archived",
}

export type CaliforniaLicenseType = {
  label: string;
  value: "CA-DOC" | "CA-DFPI" | "Both";
};

export type CaliforniaLicenseNumber = {
  rmlaNumber?: string;
  financingNumber?: string;
};

type Broker = {
  id: string;
  sk: string;
  activeLoCount?: string;
  lenderName: string;
  streetLine1: string;
  streetLine2?: string;
  web?: string;
  cityName: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  NMLScompany: string;
  statusDate?: string;
  logo: string;
  contactStartDate: string;
  contactEndDate: string;
  commissionLoan: number;
  lenderSource: string;
  addedToBetaLaunch?: boolean;
  dateAddedToBeta?: string;
  statesLicensed: string[];
  licenseType?: CaliforniaLicenseType;
  licenseNumber?: CaliforniaLicenseNumber;
  status: BrokerStatus;
  inviteCount: number;
  acceptedInvitesCount: number;
  loCount: number;
  foundedCount: number;
  pointOfContact: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    secundaryPhone: string;
  };
  accountExecutiveId: string;
  wholesaleEntity: string;
  accountManager: string;
  agreementOnFile?: boolean;
  w9OnFile?: boolean;
  paymentInstructionOnFile?: boolean;
  stateName: string;
  counterPartyId: string;
  privateLabelId?: string;
  tempCounterPartyId: string; //Only exists while changing payment information
};

export default Broker;
